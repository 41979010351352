import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout/Index';
// import Layout from '@/layout'
Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'login',
        component: () =>
            import ('../views/login/Index')
    },
    {
        path: '/login',
        name: 'login',
        component: () =>
            import ('../views/login/Index')
    },
    {
        path: '/data-center',
        name: 'dataCenter',
        component: () =>
            import ('../views/data-center/Index')
    },
    {
        path: '/data-center-pack',
        name: 'DataCenterPack',
        component: () =>
            import ('../views/data-center-pack/Index')
    },
    {
        path: '/data-center-cell',
        name: 'DataCenterCell',
        component: () =>
            import ('../views/data-center-cell/Index')
    },
    {
        path: '/monitoring-screen',
        name: 'MonitoringScreen',
        component: () =>
            import ('../views/monitoring-screen/Index')

    },
    {
        path: '/alarm-log',
        name: 'AralmLog',
        component: () =>
            import ('../views/alarm-log/Index')
    },
    {
        path: '/smart-energy',
        name: 'Smartenergy',
        component: () =>
            import ('../views/smart-energy/Index')
    },
    {
        path: '/upgrade-management',
        meta: { title: '升级管理' },
        name: 'UpgradeManagement',
        component: () =>
            import ('../views/upgrade/upgrade-management/Index')
    },
    {
        path: '/upgrade-log',
        meta: { title: '升级日志' },
        name: 'UpgradeLog',
        component: () =>
            import ('../views/upgrade/upgrade-log/Index')
    },
    {
        path: '/version-management',
        meta: { title: '版本管理' },
        name: 'VersionManagement',
        component: () =>
            import ('../views/upgrade/version-management/Index')

    },
    {
        path: '/add-customer',
        name: 'AddCustomer',
        meta: { title: '新增记录' },
        component: () =>
            import ('../views/customer-center/customer-record/AddCustomer')
    },

    {
        path: '/',
        name: 'Layout',
        component: Layout,
        redirect: '/work-bench',
        meta: { title: '工作台' },
        children: [{
                path: '/work-bench',
                meta: { title: '工作台', affix: true },
                name: 'WorkBench',
                component: () =>
                    import ('../views/work-bench/Index')
            },
            {
                path: '/performance-analysis',
                name:'PerformanceAnalysis',
                meta:{ title:'性能分析'},
                component: () =>
                    import ('../views/performance-analysis/index')
            },
            {
                path: '/device-control',
                name: 'DeviceControl',
                meta: { title: '设备控制' },
                component: () =>
                    import ('../views/status-management/device-control/index')
            },
            {
                path: '/device-management',
                name: 'DeviceManagement',
                meta: { title: '设备管理' },
                component: () =>
                    import ('../views/asset-management/device-management/Index')
            }, {
                path: '/map-monitoring',
                name: 'MapMonitoring',
                meta: { title: '运维监控' },
                component: () =>
                    import ('../views/map-monitoring/Index')
            },
            {
                path: '/info-customer',
                name: 'InfoCustomer',
                meta: { title: '查看记录' },
                component: () =>
                    import ('../views/customer-center/customer-record/InfoCustomer')
            },
            {
                path: '/site-management',
                name: 'SiteMmanagement',
                meta: { title: '站点管理' },
                component: () =>
                    import ('../views/asset-management/site-management/Index')
            },
            {
                path: '/maintenance-person-management',
                name: 'MaintenancePersonManagement',
                meta: { title: '人员管理' },
                component: () =>
                    import ('../views/asset-management/maintenance-person-management/Index')
            },
            {
                path: '/maintenance-team-management',
                name: 'MaintenanceTeamManagement',
                meta: { title: '班组管理' },
                component: () =>
                    import ('../views/asset-management/maintenance-team-management/Index')
            },
            {
                path: '/battery-management',
                name: 'BatteryManagement',
                meta: { title: '电池管理' },
                component: () =>
                    import ('../views/asset-management/battery-management/Index')
            },
            {
                path: '/institutional-management',
                name: 'InstitutionalManagement',
                meta: { title: '机构管理' },
                component: () =>
                    import ('../views/system-setting/institutional-management/Index')
            },
            {
                path: '/role-management',
                name: 'RoleManagement',
                meta: { title: '角色管理' },
                component: () =>
                    import ('../views/system-setting/role-management/Index')
            },
            {
                path: '/user-management',
                name: 'UserManagement',
                meta: { title: '用户管理' },
                component: () =>
                    import ('../views/system-setting/user-management/Index')
            },
            {
                path: '/log-management',
                name: 'LogManagement',
                meta: { title: '登录日志管理' },
                component: () =>
                    import ('../views/system-setting/log-management/Index')
            },
            {
                path: '/fault-rescue-management',
                name: 'FaultRescueManagement',
                meta: { title: '故障工单管理' },
                component: () =>
                    import ('../views/work-order-management/fault-rescue/Index')
            },
            {
                path: '/fault-rescue-management/:orderStatus',
                name: 'FaultRescueManagement',
                meta: { title: '救援工单管理' },
                component: () =>
                    import ('../views/work-order-management/fault-rescue/Index')
            },
            {
                path: '/rescue-detail/:jobCode',
                name: 'RescueDetail',
                meta: { title: '故障救援详情' },
                component: () =>
                    import ('../views/work-order-management/fault-rescue/RescueDetail')
            },
            {
                path: '/planned-overhaul',
                name: 'PlannedOverhaul',
                meta: { title: '检修工单管理' },
                component: () =>
                    import ('../views/work-order-management/planned-overhaul/Index')
            },
            {
                path: '/planned-overhaul/:orderStatus',
                name: 'PlannedOverhaul',
                meta: { title: '检修工单管理' },
                component: () =>
                    import ('../views/work-order-management/planned-overhaul/Index')
            },
            {
                path: '/order-detail/:jobCode',
                name: 'OrderDetail',
                meta: { title: '订单详情' },
                component: () =>
                    import ('../views/work-order-management/planned-overhaul/OrderDetail')
            },
            {
                path: '/history-alarm',
                name: 'historyAlarm',
                meta: { title: '告警消息' },
                component: () =>
                    import ('../views/msg-center/history-alarm/Index')
            },
            {
                path: '/history-alarm/:type',
                name: 'historyAlarm',
                meta: { title: '告警消息' },
                component: () =>
                    import ('../views/msg-center/history-alarm/Index')
            },
            {
                path: '/msg-management',
                name: 'MsgManagement',
                meta: { title: '公告消息' },
                component: () =>
                    import ('../views/msg-center/msg-management/Index')
            },
            {
                path: '/offline-info',
                name: 'OffLineinfo',
                meta: { title: '离线消息' },
                component: () =>
                    import ('../views/msg-center/offline-info/Index')
            },
            {
                path: '/maintenance-monitoring',
                name: 'MaintenanceMonitoring',
                meta: { title: '运维监控' },
                component: () =>
                    import ('../views/maintenance-monitoring/Index')
            },

            {
                path: '/cloud-control',
                meta: { title: '云控信息' },
                name: 'CloudControl',
                component: () =>
                    import ('../views/msg-center/cloud-control/Index')
            },
            {
                path: '/basic-settings',
                meta: { title: '消息设置' },
                name: 'BasicSettings',
                component: () =>
                    import ('../views/system-setting/basic-settings/Index')
            },

            {
                path: '/device-detail',
                name: 'DeviceDetail',
                meta: { title: '设备详情' },
                component: () =>
                    import ('../views/asset-management/device-management/DeviceDetail')
            },

            {
                path: '/setup-detail',
                name: 'SetupDetail',
                meta: { title: '设备设置' },
                component: () =>
                    import ('../views/status-management/device-control/SetupDetail')
            },
            {
                path: '/back-job',
                name: 'BackJob',
                meta: { title: '回调工单' },
                component: () =>
                    import ('../views/work-order-management/back-job/Index')
            },
            {
                path: '/sale-job',
                name: 'SaleJob',
                meta: { title: '售后工单' },
                component: () =>
                    import ('../views/work-order-management/sale-job/Index')
            },
            {
                path: '/supplier-management',
                name: 'SupplierManagement',
                meta: { title: '供应商管理' },
                component: () =>
                    import ('../views/asset-management/supplier-management/Index')
            },
            {
                path: '/supplier-Operation',
                name: 'SupplierOperation',
                meta: { title: '供应商操作' },
                component: () =>
                    import ('../views/asset-management/supplier-management/Operation')
            },
            {
                path: '/status-management',
                name: 'StatusManagement',
                meta: { title: '状态管理' },
                component: () =>
                    import ('../views/status-management/status-man/Index')
            },
            {
                path: '/mine-job',
                name: 'MineJob',
                meta: { title: '我的工单' },
                component: () =>
                    import ('../views/work-order-management/mine-job/Index')
            },
            {
                path: '/customer-record',
                name: 'CustomerRecord',
                meta: { title: '客服记录' },
                component: () =>
                    import ('../views/customer-center/customer-record/Index')
            },
            {
                path: '/knowledge-base',
                name: 'KnowledgeBase',
                meta: { title: '知识库管理' },
                component: () =>
                    import ('../views/customer-center/knowledge-base/Index')
            },
            {
                path: '/customer-information',
                name: 'CustomerInformation',
                meta: { title: '客服工单' },
                component: () =>
                    import ('../views/msg-center/customer-information/Index')
            },
            {
                path: '/add-role',
                name: 'AddWin',
                meta: { title: '新增角色' },
                component: () =>
                    import ('../views/system-setting/role-management/components/AddWin')
            },
            {
                path: '/algorithm-management',
                name: 'AlgorithmManagement',
                meta: { title: '算法管理' },
                component: () =>
                    import ('../views/customer-center/algorithm-management/Index')
            },
            {
                path: '/vehicle-management',
                name: 'VehicleManagement',
                meta: { title: '车辆管理' },
                component: () =>
                    import ('../views/customer-center/vehicle-management/Index')
            },
            {
                path: '/week-statisti',
                name: 'WeekStatisti',
                meta: { title: '报表统计' },
                component: () =>
                    import ('../views/report/week-statisti/Index')
            },
            {
                path: '/customer-report',
                name: 'CustomerReport',
                meta: { title: '客服周报' },
                component: () =>
                    import ('../views/report/customer-report/Index')
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: "/", //部署的子路径
    base: process.env.BASE_URL,
    routes
})

export default router