<template>
  <div class="nav-container">
    <div class="nav-container-scroll">
      <div
        class="nav-item"
        v-for="(item, index) in navData"
        :key="index"
        v-show="item.visible"
      >
        <div
          :class="{
            'nav-item-content': true,
            'nav-item-content-active': item.active,
          }"
          @click="changeFoldStatus(index)"
        >
          <el-popover
            popper-class="sub-nav-popover"
            v-if="
              item.children !== undefined &&
              item.children.length !== 0 &&
              isFold
            "
            placement="right"
            width="110"
            trigger="hover"
          >
            <div class="sub-nav-popover-container">
              <div
                class="sub-nav-popover-item"
                v-for="subItem in item.children"
                :key="subItem.path"
                @click="jumpNav(subItem.path)"
                v-show="subItem.visible"
              >
                {{ subItem.meta.title }}
              </div>
            </div>
            <div
              slot="reference"
              :class="{ 'nav-item-icon': true, 'nav-item-ico-fold': isFold }"
              :title="item.meta.title"
            >
              <img
                v-if="!item.active"
                class="nav-icon"
                :src="require(`@/assets/img/${item.icon}.png`)"
              />
              <img
                v-else
                class="nav-icon"
                :src="require(`@/assets/img/${item.icon}-active.png`)"
              />
            </div>
          </el-popover>
          <div
            v-else
            :class="{ 'nav-item-icon': true, 'nav-item-ico-fold': isFold }"
            :title="item.meta.title"
          >
            <img
              v-if="!item.active"
              class="nav-icon"
              :src="require(`@/assets/img/${item.icon}.png`)"
            />
            <img
              v-else
              class="nav-icon"
              :src="require(`@/assets/img/${item.icon}-active.png`)"
            />
          </div>
          <span v-if="!isFold">
            <span class="nav-name">{{ item.meta.title }}</span>
            <i
              v-if="item.fold && item.children !== undefined"
              class="el-icon-arrow-down"
            ></i>
            <i
              v-if="!item.fold && item.children !== undefined"
              class="el-icon-arrow-up"
            ></i>
          </span>
        </div>
        <div
          v-if="
            item.children && item.children.length > 0 && !item.fold && !isFold
          "
          class="sub-nav-container"
        >
          <div
            v-for="(subItem, index) in item.children"
            :key="index"
            :class="{
              'sub-nav-item': true,
              'sub-nav-item-active': subItem.active,
            }"
            @click="jumpNav(subItem.path)"
            v-show="subItem.visible"
          >
            {{ subItem.meta.title }}
          </div>
        </div>
      </div>
    </div>
    <div @click="changeFoldHandel" class="nav-bottom">
      <div class="nav-expand-icon">
        <img v-if="!isFold" :src="require('@/assets/img/nav-fold.png')" />
        <img v-else :src="require('@/assets/img/nav-expand.png')" />
      </div>
      <div v-if="!isFold" class="nav-expand-title">
        <span class="title-span">收起</span>
      </div>
    </div>
  </div>
</template>

<script>
import "./Nav.less";
import { getFunExist } from "@/utils/commonFun";
export default {
  name: "Nav",
  props: {
    isFold: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      navData: [
        {
            name: 'WorkBench',
            icon: 'nav-workbench',
            fold: true, // 是否折叠
            meta: { title: '工作台' },
            active: false,
            path: '/work-bench',
            visible: getFunExist('work_bench')
        },
        {
            name: 'MapMonitoring',
            icon: 'nav-maintenance',
            fold: true, // 是否折叠
            active: false,
            meta: { title: '运维监控' },
            path: '/map-monitoring',
            visible: getFunExist('operation_monitoring')
        },
        {
          name: "DeviceManagement",
          meta: { title: '运维管理' },
          icon: "nav-assets",
          fold: true, // 是否折叠
          active: false,
          visible: getFunExist("asset_management"),
          children: [
            {
              name: "DeviceManagement",
              active: false,
              meta: { title: '设备管理' },
              path: "/device-management",
              visible: getFunExist("device_management"),
            },
            {
              name: "SupplierManagement",
              active: false,
              meta: { title: '供应商管理' },
              path: "/supplier-management",
              visible: getFunExist("supplier_management"),
            },
          ],
        },
        {
          name: "PerformanceAnalysis",
          meta: { title: '性能分析' },
          icon: "nav-performance-analysis",
          fold: true, // 是否折叠
          active: false,
          path: '/performance-analysis',
          visible: getFunExist("performance-analysis"),
        },
        {
          name: "大屏",
          icon: "nav-big-screen",
          fold: true, // 是否折叠
          active: false,
          meta: { title: '大屏' },
          visible: getFunExist("big_screen"),
          children: [
            {
              name: "安全预警平台",
              active: false,
              meta: { title: '安全预警平台' },
              path: "/monitoring-screen",
              visible: getFunExist("monitoring-screen"),
            },
            {
              name: "安全预警日志",
              active: false,
              meta: { title: '安全预警日志' },
              path: "/alarm-log",
              visible: getFunExist("alarm-log"),
            },
            {
              name: "智慧能源平台",
              active: false,
              meta: { title: '智慧能源平台' },
              path: "/smart-energy",
              visible: getFunExist("smart-energy"),
            },
          ],
        },
        
        {
          name: "信息中心",
          icon: "nav-message",
          meta: { title: '信息中心' },
          fold: true, // 是否折叠
          active: false,
          visible: getFunExist("message_center"),
          children: [
            {
              name: "CloudControl",
              active: false,
              path: "/cloud-control",
              meta: { title: '云控信息' },
              visible: getFunExist("cloud_control"),
            },
            // {
            //   name: "CustomerInformation",
            //   active: false,
            //   path: "/customer-information",
            //   meta: { title: '客服工单' },
            //   visible: getFunExist("customer_information"),
            // },
          ],
        },
        {
          name: "工单管理",
          icon: "nav-work",
          meta: { title: '工单管理' },
          fold: true, // 是否折叠
          active: false,
          visible: getFunExist("workorder_management"),
          children: [
            {
              name: "BackJob",
              meta: { title: '回调工单' },
              active: false,
              path: "/back-job",
              visible: getFunExist("back_job"),
            },
            {
              name: "SaleJob",
              active: false,
              meta: { title: '售后工单' },
              path: "/sale-job",
              visible: getFunExist("sale_job"),
            },
            {
              name: "MineJob",
              active: false,
              path: "/mine-job",
              meta: { title: '我的工单' },
              visible: getFunExist("mine_job"),
            },
          ],
        },

        {
          name: "客服中心",
          icon: "nav-report",
          fold: true, // 是否折叠
          active: false,
          meta: { title: '客服中心' },
          visible: getFunExist("customer_center"),
          children: [
            {
              name: "CustomerRecord",
              active: false,
              meta: { title: '客服记录' },
              path: "/customer-record",
              visible: getFunExist("customer_record"),
            },
            {
              name: "KnowledgeBase",
              active: false,
              meta: { title: '知识库管理' },
              path: "/knowledge-base",
              visible: getFunExist("knowledge_base"),
            },
            // {
            //   name: "AddCustomer",
            //   active: false,
            //   meta: { title: '新增记录' },
            //   path: "/add-customer",
            //   visible: false,
            // },
            // {
            //   name: "算法管理",
            //   active: false,
            //   meta: { title: '算法管理' },
            //   path: "/algorithm-management",
            //   visible: true,
            // },
            // {
            //   name: "车辆管理",
            //   active: false,
            //   meta: { title: '车辆管理' },
            //   path: "/vehicle-management",
            //   visible: true,
            // },
          ],
        },
        {
          name: "状态管理",
          icon: "nav-report",
          meta: { title: '状态管理' },
          fold: true, // 是否折叠
          active: false,
          visible: getFunExist("state_management"),
          children: [
            {
              name: "StatusManagement",
              active: false,
              meta: { title: '状态管理' },
              path: "/status-management",
              visible: getFunExist("status_management"),
            },
            {
              name: "DeviceControl",
              active: false,
              meta: { title: '设备控制' },
              path: "/device-control",
              visible: getFunExist("device_control"),
            },
          ],
        },
        {
          name: "报表统计",
          icon: "nav-report",
          meta: { title: '报表统计' },
          fold: true, // 是否折叠
          active: false,
          visible: getFunExist('report_statisti'),
          children: [
            {
              name: "报表统计",
              active: false,
              meta: { title: '报表统计' },
              path: "/week-statisti",
              visible: getFunExist('week_statisti'),
            },
            {
              name: "客服周报",
              active: false,
              meta: { title: '客服周报' },
              path: "/customer-report",
              visible: getFunExist('customer_report'),
            },
          ],
        },

        {
            name: '升级管理',
            icon: 'nav-message',
            fold: true, // 是否折叠
            active: false,
            visible: true,
            meta: { title: '升级管理' },
            visible: getFunExist('promotion_management'),
            children: [
                {
                    name: '升级管理',
                    active: false,
                    path: '/upgrade-management',
                    meta: { title: '升级管理' },
            visible: getFunExist('upgrade_management')
                },
                {
                    name: '升级日志',
                    active: false,
                    path: '/upgrade-log',
                    meta: { title: '升级日志' },
            visible: getFunExist('upgrade_log')
                },
                {
                    name: '版本管理',
                    active: false,
                    meta: { title: '版本管理' },
                    path: '/version-management',
            visible: getFunExist('version_management')
                }
            ]
        },
        {
          name: "系统设置",
          icon: "nav-set",
          fold: true, // 是否折叠
          active: false,
          meta: { title: '系统设置' },
          visible: getFunExist("system_settings"),
          children: [
            {
              name: "BasicSettings",
              path: "/basic-settings",
              active: false,
              meta: { title: '消息设置' },
              visible: getFunExist("basic_settings"),
            },
            {
              name: "InstitutionalManagement",
              path: "/institutional-management",
              active: false,
              meta: { title: '机构管理' },
              visible: getFunExist("organization_management"),
            },
            {
              name: "UserManagement",
              path: "/user-management",
              active: false,
              meta: { title: '用户管理' },
              visible: getFunExist("user_management"),
            },
            // {
            //   name: "角色管理",
            //   path: "/role-management",
            //   active: false,
            //   visible: getFunExist("role_management"),
            // },
            {
              name: "LogManagement",
              path: "/log-management",
              active: false,
              meta: { title: '登录日志管理' },
              visible: getFunExist("login_log_management"),
            },
          ],
        },
      ],
    };
  },
  watch: {
    $route(to) {
      this.activeNavToRoute(to.path);
    },
  },
  methods: {
    changeFoldStatus(_index) {
      const navDataCopy = JSON.parse(JSON.stringify(this.navData));
      const item = navDataCopy.find((item, index) => index === _index);
      item.fold = !item.fold;
      this.navData = navDataCopy;
      if (this.$route.path === item.path) {
        return;
      }
      if (item.path && item.meta.title !== "大屏") {
        this.$router.push(item.path);
      }
    },
    activeNavToRoute(path) {
      if (path.indexOf("/rescue-detail") >= 0) {
        path = "/fault-rescue-management";
      }
      if (path.indexOf("/history-alarm") >= 0) {
        path = "/history-alarm";
      }
      if (path.indexOf("/fault-rescue-management") >= 0) {
        path = "/fault-rescue-management";
      }
      if (path.indexOf("/planned-overhaul") >= 0) {
        path = "/planned-overhaul";
      }
      if (path === "/order-detail") {
        path = "/planned-overhaul";
      }
      if (path === "/device-detail") {
        path === "/device-detail";
      }
      if (path === "/setup-detail") {
        path === "/setup-detail";
      }
      if (path === "/supplier-Operation") {
        path === "/supplier-Operation";
      }
      if (path === "/add-customer") {
        path === "/add-customer";
      }
      if (path === '/info-customer') {
        path === '/info-customer'
      }
      if (path === "/add-role") {
        path === "/add-role";
      }
      const navInfo = [];
      const navDataCopy = JSON.parse(JSON.stringify(this.navData));
      navDataCopy.forEach((nav) => {
        nav.active = false;
        if (nav.children) {
          nav.children.forEach((subNav) => {
            subNav.active = false;
          });
        }
      });
      navDataCopy.forEach((nav) => {
        if (nav.path === path) {
          nav.active = true;
          navInfo.push(nav.meta.title);
        }
        if (nav.children) {
          nav.children.forEach((subNav) => {
            if (subNav.path === path) {
              subNav.active = true;
              nav.active = true;
              nav.fold = false;
              navInfo.push(nav.meta.title);
              navInfo.push(subNav.meta.title);
            }
          });
        }
      });
      this.$emit("navChange", navInfo);
      this.navData = navDataCopy;
    },
    jumpNav(path) {
      if (this.$route.path === path) {
        return;
      }
      if (
        path === "/data-center" ||
        path === "/data-center-pack" ||
        path === "/data-center-cell" ||
        path === "/monitoring-screen" ||
        path === "/alarm-log" || 
        path === "/smart-energy" || 
        path === "/upgrade-management" || 
        path === "/upgrade-log" || 
        path === "/version-management" || 
        path === "/add-customer" 
      ) {
        window.open(path);
        return;
      }
      if (path) {
        this.$router.push(path);
      }
    },
    changeFoldHandel() {
      this.$emit("foldChange");
    },
  },
  mounted() {
    this.activeNavToRoute(this.$route.path);
  },
};
</script>