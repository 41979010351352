var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav-container"},[_c('div',{staticClass:"nav-container-scroll"},_vm._l((_vm.navData),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.visible),expression:"item.visible"}],key:index,staticClass:"nav-item"},[_c('div',{class:{
          'nav-item-content': true,
          'nav-item-content-active': item.active,
        },on:{"click":function($event){return _vm.changeFoldStatus(index)}}},[(
            item.children !== undefined &&
            item.children.length !== 0 &&
            _vm.isFold
          )?_c('el-popover',{attrs:{"popper-class":"sub-nav-popover","placement":"right","width":"110","trigger":"hover"}},[_c('div',{staticClass:"sub-nav-popover-container"},_vm._l((item.children),function(subItem){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(subItem.visible),expression:"subItem.visible"}],key:subItem.path,staticClass:"sub-nav-popover-item",on:{"click":function($event){return _vm.jumpNav(subItem.path)}}},[_vm._v(" "+_vm._s(subItem.meta.title)+" ")])}),0),_c('div',{class:{ 'nav-item-icon': true, 'nav-item-ico-fold': _vm.isFold },attrs:{"slot":"reference","title":item.meta.title},slot:"reference"},[(!item.active)?_c('img',{staticClass:"nav-icon",attrs:{"src":require(("@/assets/img/" + (item.icon) + ".png"))}}):_c('img',{staticClass:"nav-icon",attrs:{"src":require(("@/assets/img/" + (item.icon) + "-active.png"))}})])]):_c('div',{class:{ 'nav-item-icon': true, 'nav-item-ico-fold': _vm.isFold },attrs:{"title":item.meta.title}},[(!item.active)?_c('img',{staticClass:"nav-icon",attrs:{"src":require(("@/assets/img/" + (item.icon) + ".png"))}}):_c('img',{staticClass:"nav-icon",attrs:{"src":require(("@/assets/img/" + (item.icon) + "-active.png"))}})]),(!_vm.isFold)?_c('span',[_c('span',{staticClass:"nav-name"},[_vm._v(_vm._s(item.meta.title))]),(item.fold && item.children !== undefined)?_c('i',{staticClass:"el-icon-arrow-down"}):_vm._e(),(!item.fold && item.children !== undefined)?_c('i',{staticClass:"el-icon-arrow-up"}):_vm._e()]):_vm._e()],1),(
          item.children && item.children.length > 0 && !item.fold && !_vm.isFold
        )?_c('div',{staticClass:"sub-nav-container"},_vm._l((item.children),function(subItem,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(subItem.visible),expression:"subItem.visible"}],key:index,class:{
            'sub-nav-item': true,
            'sub-nav-item-active': subItem.active,
          },on:{"click":function($event){return _vm.jumpNav(subItem.path)}}},[_vm._v(" "+_vm._s(subItem.meta.title)+" ")])}),0):_vm._e()])}),0),_c('div',{staticClass:"nav-bottom",on:{"click":_vm.changeFoldHandel}},[_c('div',{staticClass:"nav-expand-icon"},[(!_vm.isFold)?_c('img',{attrs:{"src":require('@/assets/img/nav-fold.png')}}):_c('img',{attrs:{"src":require('@/assets/img/nav-expand.png')}})]),(!_vm.isFold)?_c('div',{staticClass:"nav-expand-title"},[_c('span',{staticClass:"title-span"},[_vm._v("收起")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }