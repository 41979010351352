// import { login, logout, getInfo } from '@/api/user'
// import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'
// import { postJson, getSort } from '@/api/page'

const getDefaultState = () => {
    return {
        token: localStorage.getItem('user-token'),
        name: '',
        avatar: '',
        role: []
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_ROLE: (state, data) => {
        state.role = data
    }
}

const actions = {
    // user login
    // login({ commit }, userInfo) {
    //     const { username, password } = userInfo
    //     return new Promise((resolve, reject) => {
    //         login({ username: username.trim(), password: password }).then(response => {
    //             const { data } = response
    //             commit('SET_TOKEN', data)
    //             setToken(data)
    //             resolve()
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },

    setRole({ commit }, data) {
        commit('SET_ROLE', data)
    },

    // get user info
    // getInfo({ commit, state }) {
    //     return new Promise((resolve, reject) => {
    //         getInfo().then(response => {
    //             const { data } = response
    //             if (!data) {
    //                 return reject('Verification failed, please Login again.')
    //             }

    //             localStorage.setItem('userId', response.data.id)
    //             localStorage.setItem('nickName', response.data.userName)
    //             const { name, avatar } = data
    //             commit('SET_NAME', name)
    //             commit('SET_AVATAR', avatar)
    //             postJson('/api/v1/sys/logInrole/tree', { id: response.data.id }).then(res => {
    //                 if (res.code === 200) {
    //                     commit('SET_ROLE', res.data.checked)
    //                 }
    //             })
    //             resolve(data)
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },

    // user logout
    // logout({ commit, state, dispatch }) {
    //     return new Promise((resolve, reject) => {
    //         logout(state.token).then(() => {
    //             dispatch('tagsView/delView', null, { root: true })
    //             removeToken() // must remove  token  first
    //             resetRouter()
    //             commit('RESET_STATE')
    //             resolve()
    //         }).catch(error => {
    //             dispatch('tagsView/delView', null, { root: true })
    //             reject(error)
    //         })
    //     })
    // },

    // remove token
    // resetToken({ commit }) {
    //     return new Promise(resolve => {
    //         removeToken() // must remove  token  first
    //         commit('RESET_STATE')
    //         resolve()
    //     })
    // },

    // async changeRoles({ commit, dispatch }, role) {
    //     const token = role + '-token'

    //     commit('SET_TOKEN', token)
    //     setToken(token)

    //     const { roles } = await dispatch('getInfo')

    //     resetRouter()

    //     // generate accessible routes map based on roles
    //     const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
    //         // dynamically add accessible routes
    //     router.addRoutes(accessRoutes)

    //     // reset visited views and cached views
    //     dispatch('tagsView/delAllViews', null, { root: true })
    // }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}