import axios from '@/utils/axios';

const institutionalManagement = {
    namespaced: true,
    state: () => ({
        orgTreeData: [],
        orgListData: {
            list: [],
            total: 0
        }
    }),
    mutations: {
        setOrgTreeData(state, payload) {
            // 变更状态
            state.orgTreeData = payload;
        },
        setOrgListData(state, payload) {
            state.orgListData = payload;
        }
    },
    actions: {
        async fetchOrgTreeData({ commit }, products) {
            const { orgId } = products.payload;
            const rs = await axios.get(`/api/org/getOrgTree/${orgId}`);
            commit('setOrgTreeData', rs);
        },
        async fetchOrgListData({ commit }, products) {
            const { page, pageSize, orgId, orgLeave, keyWord } = products.payload;
            const rs = await axios.get(`/api/org/pageList/${page}/${pageSize}`, {
                orgId,
                orgLeave,
                keyWord
            });
            commit('setOrgListData', rs);
        },
    },
    getters: {

    }
};
export default institutionalManagement;