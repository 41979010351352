<template>
  <div class="userinfo-container">
    <el-popover
      popper-class="user-info-popover"
      placement="bottom"
      trigger="hover"
    >
      <div class="user-info-down" @click="updateHandle">
        <div class="user-down-item">
          <img :src="require('@/assets/img/user-icon.png')" />
          <span>修改密码</span>
        </div>
        <div class="user-down-item" @click="logoutHandel">
          <img :src="require('@/assets/img/logout.png')" />
          <span>退出登录</span>
        </div>
      </div>
      <span class="userinfo-span" slot="reference">
        <img class="head-icon" :src="require('@/assets/img/head.png')" />
        <span class="user-name">{{ userInfo.userName }}</span>
        <i class="el-icon-arrow-down user-down-icon"></i>
      </span>
    </el-popover>
    <el-dialog
      :visible.sync="visible"
      title="修改密码"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="30%"
      :destroy-on-close="true"
      @close="closeHandle"
    >
      <div class="handle-content">
        <el-form
          ref="dataForm"
          :model="dataForm"
          :rules="rules"
          class="demo-form-inline"
        >
          <el-form-item label="新密码" :label-width="'120px'" prop="value">
            <el-input
              v-model="dataForm.value"
              placeholder="新密码"
              clearable
              :style="{ width: '315px' }"
            />
          </el-form-item>
          <el-form-item label="确认密码" :label-width="'120px'" prop="pwd">
            <el-input
              v-model="dataForm.pwd"
              placeholder="类型名称"
              clearable
              :style="{ width: '315px' }"
            />
          </el-form-item>
        </el-form>
      </div>
      <template slot="footer">
        <el-button
          type="primary"
          :loading="loading"
          @click="dataFormSubmitHandle()"
          >确认</el-button
        >
        <el-button @click="visible = false">取消</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import "./UserInfo.less";
import axios from "@/utils/axios";
export default {
  name: "UserInfo",
  computed: {
    userInfo() {
      return this.$store.state.currentUserInfo;
    },
    rules() {
      return {
        value: [{ required: true, message: "必填项不能为空", trigger: "blur" }],
        pwd: [{ required: true, message: "必填项不能为空", trigger: "blur" }],
      };
    },
  },
  data() {
    return {
      visible: false,
      dataForm: {
        value: null,
        pwd: null,
      },
      loading: false,
    };
  },
  mounted() {},
  methods: {
    logoutHandel() {
      this.$store.dispatch("tagsView/delAllViews", this.$route);
      this.$router.push("/login");
    },
    updateHandle() {
      this.visible = true;
    },
    closeHandle() {
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        this.dataForm = {
          value: null,
          pwd: null,
        };
      });
    },
    dataFormSubmitHandle() {
      let { dataForm } = this;
      if (dataForm.value !== dataForm.pwd) {
        return this.$message({
          message: "两次输入密码必须一致",
          type: "error",
          duration: 500,
        });
      }
      this.$refs["dataForm"].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.loading = true;
        dataForm.id = localStorage.getItem("userId")
        axios
          .post(`/api/userInfo/updatePwd`, dataForm)
          .then((res) => {
            if (res) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 500,
                onClose: () => {
                  this.visible = false;
                  this.$emit("refreshDataList");
                },
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            this.loading = false;
            return this.$message.error(err);
          });
      });
    },
  },
};
</script>