import axios from '@/utils/axios';

const siteManagement = {
    namespaced: true,
    state: () => ({
        data: {
            records: [],
            total: 0
        }
    }),
    mutations: {
        setData (state, payload) {
            // 变更状态
            state.data = payload;
        }
    },
    actions: {
        async fetchData({ commit }, products) {
            const { page, pageSize, params } = products.payload;
            const rs = await axios.get(`/api/site/pageList/${page}/${pageSize}`, params);
            commit('setData', rs);
        }
    },
    getters: {

    }
};
export default siteManagement;