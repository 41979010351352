<template>
    <div class="header-container">
        <img v-if="currentUser.org.orgLogo" class="logo-icon" :src="currentUser.org.orgLogo"/>
        <img v-else class="logo-icon" :src="require('@/assets/img/logo.png')"/>
        <span class="header-title">{{ currentUser.org.platformName }}</span>
        <el-popover
            placement="bottom-end"
            title="消息通知"
            width="350"
            trigger="click"
            content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
        >
            <div class="msg-info-container">
                <div class="msg-item" v-for="item in msgList" :key="item.id">
                    【<div class="msg-title" :title="item.msgTitle">{{item.msgTitle}}</div>】
                    <div class="msg-content">{{ item.msgContent }}</div>
                    <div class="msg-icon"></div>
                </div>
            </div>
            <img slot="reference" class="warn-icon" :src="require('@/assets/img/warn.png')"/>
        </el-popover>
        <span v-if="msgList.length > 0" class="msg-count" :title="msgList.length">{{msgList.length}}</span>
        <UserInfo />
    </div>
</template>

<script>
import UserInfo from './UserInfo';
import './Header.less'
import axios from '@/utils/axios';
export default {
    name: 'Header',
    components: {
        UserInfo
    },
    data() {
        return {
            msgList: [],
            timer: null
        }
    },
    computed: {
        currentUser() {
            const currentUserLocal = localStorage.getItem('currentUser');
            if (currentUserLocal) {
                return JSON.parse(currentUserLocal)
            } else {
                return { }
            }
        }
    },
    methods: {
        getData() {
            axios.get(`/api/msgLog/pageList/1/10000`, {
                readStatus: 0,
            }).then(rs => {
                this.msgList = rs.records;
            }).catch(err =>  {
                this.$message.error(err);
            });
        }
    },
    mounted() {
        // this.getData();
        // this.timer = setInterval(this.getData, 60000);
    },
    beforeDestroy() {
        clearInterval(this.timer);
    }
}
</script>