import Vue from 'vue'
import Vuex from 'vuex'
import siteManagement from './asset-management/site-management';
import institutionalManagement from './system-setting/institutional-management';
import maintenanceTeamManagement from './asset-management/maintenance-team-management';
import axios from '@/utils/axios';
import tagsView from './modules/tagsView'
import user from './modules/user'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        currentUserInfo: {
            org: {
                connectEmail: "632479841@qq.com",
                connectMobile: "15023314850",
                connectPersonName: "张小龙",
                createBy: "",
                createTime: "2021-01-21 11:08:21",
                del: false,
                id: 1,
                orgAddress: "沙坪坝67号",
                orgCode: ",1,",
                orgId: "1",
                orgLeave: 3,
                orgLogo: "http://tee.png",
                orgName: "智锂物联",
                orgShortInfo: "简介:专业电池供应商",
                parentId: 0,
                platformName: " 智锂物联运维平台",
                updateBy: "",
                updateTime: "2021-01-21 11:08:21",
            },
            userName: ''
        }
    },
    mutations: {
        setCurrentUserInfo(state, payload) {
            state.currentUserInfo = payload;
        }
    },
    actions: {
        async getCurrentUserInfo({ commit }) {
            const rs = await axios.get('/api/userInfo/getCurrLoginUserInfo');
            commit('setCurrentUserInfo', rs);
            return rs;
        }
    },
    modules: {
        siteManagement,
        institutionalManagement,
        maintenanceTeamManagement,
        tagsView,
    }
})