export function getFunExist(id) {
    let flag = false;
    const currentFuns = localStorage.getItem('currentFuns');
    if (currentFuns) {
        const currentFunsObj = JSON.parse(currentFuns)
        if (currentFunsObj.includes(id)) {
            flag = true;
        }
    }
    return flag;
}

export const downloadFile = (fileStream, name, extension, type = "") => {
  const blob = new Blob([fileStream], {type});
  const fileName = `${name}.${extension}`;
  if ("download" in document.createElement("a")) {
    const elink = document.createElement("a");
    elink.download = fileName;
    elink.style.display = "none";
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href);
    document.body.removeChild(elink);
  } else {
    navigator.msSaveBlob(blob, fileName);
  }
};


export const secondToDate = (result) => {
    const h = Math.floor(result / 3600);
    const m = Math.floor((result / 60 % 60));
    const s = Math.floor((result % 60));
    return result = (h > 9 ? h : `0${h}`) + "小时" + (m > 9 ? m : `0${m}`) + "分钟" + (s > 9 ? s : `0${s}`) + "秒";
}