<template>
    <div class="zlwl-layout-container">
        <div class="layout-header">
            <Header />
        </div>
        <div class="layout-content">
            <div class="content-left" :style="{width: isFold ? '69px' : '240px'}">
                <Nav :isFold="isFold" @navChange="navChangeHandel" @foldChange="foldChangeHandel"/>
            </div>
            
            <div class="content-right" :style="{width: isFold ? 'calc(100% - 69px)' : 'calc(100% - 240px)'}">
                <tags-view  />
                <div v-if="!isOrderDetail" class="nav-info-container">
                    {{ navInfo[1] ?  navInfo[1] : navInfo[0]}}
                </div>
                <div class="page-content" :style="{height: isOrderDetail ? '100%' : 'calc(100% - 90px)'}">
                    <keep-alive :include="cachedViews">
                        <router-view :key="key" />
                    </keep-alive>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Nav from './Nav';
import Header from './Header'
import './Index.less';
import TagsView  from './components/TagsView/index.vue'
export default {
    name: 'Layout',
    components: {
        Nav,
        Header,
        TagsView,
    },
    data() {
        return {
            navInfo: [],
            isFold: false
        }
    },
    computed: {
        isOrderDetail() {
            const path = this.$route.path;
            const pathArray = ['/rescue-detail', '/order-detail', '/maintenance-monitoring','/map-monitoring'];
            if (pathArray.find(item => path.indexOf(item) >= 0)) {
                return true;
            } else {
                false
            }
        },
        cachedViews() {
            return this.$store.state.tagsView.cachedViews
        },
        key() {
            return this.$route.path
        }
    },
    methods: {
        navChangeHandel(data) {
            this.navInfo = data;
        },
        foldChangeHandel() {
            this.isFold = !this.isFold;
        }
    },
    mounted() {
        this.$store.dispatch({ type: 'getCurrentUserInfo' });
    }
}
</script>